<template>
  <div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapMutations} from "vuex";

export default {
  name: "transfernotoken",
  created() {
    const url = window.location.href;             //获取当前url
    const dz_url = url.split('#')[1];    //获取#/之前的字符串
    const cs = dz_url.split('?')[1];     //获取?之后的参数字符串

    console.log()
    this.axios.get('https://www.gjegm.ltd/czsczq/userinfo/getNoToken', (response) => {
      if (cs === undefined){
        ElMessage.error('状态有误，请联系管理员!');
      }else {
        this.SET_TOKEN(response.obj);
        this.$router.push(cs.split("=")[1]);
      }
    },{
      name: 'gj',
      bdst: '项目公司',
      department: '科技信息部'
    })

  },
  methods:{
    ...mapMutations(['SET_TOKEN']),
  }
}
</script>

<style scoped>

</style>